import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="elocator"></a><h3>ELOCATER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" width="100%">
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80453.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d6.
    <a id="elocator-requirements"></a><h6>Requirements</h6>
To qualify to become an elocater, a character must fulfill all the
following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Base Attack Bonus:</span> +3.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
8 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="featsAll.html#mobility" style={{
        "color": "rgb(87, 158, 182)"
      }}>Mobility</a>,
      <a href="featsAll.html#spring-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spring Attack</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psionics:</span> Able to
manifest 1st-level powers.</p>
    <h6>Class Skills</h6>
The elocater&#8217;s class skills (and the key ability for each skill) are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis </a>(Wis), <a href="skillsAll.html#balance" style={{
      "color": "rgb(87, 158, 182)"
    }}>Balance</a>
(Dex), <a href="skillsAll.html#climb" style={{
      "color": "rgb(87, 158, 182)"
    }}>Climb</a>
(Str), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(Int), <a href="skillsAll.html#disable-device" style={{
      "color": "rgb(87, 158, 182)"
    }}>Disable Device</a> (Int), <a href="skillsAll.html#escape-artist" style={{
      "color": "rgb(87, 158, 182)"
    }}>Escape
Artist</a> (Dex), <a href="skillsAll.html#gather-information" style={{
      "color": "rgb(87, 158, 182)"
    }}>Gather
Information</a> (Cha), <a href="skillsAll.html#hide" style={{
      "color": "rgb(87, 158, 182)"
    }}>Hide</a> (Dex), <a href="skillsAll.html#jump" style={{
      "color": "rgb(87, 158, 182)"
    }}>Jump </a>(Str),
    <a href="skillsAll.html#knowledge" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge</a> (local) (Int), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a>, <a href="skillsAll.html#listen" style={{
      "color": "rgb(87, 158, 182)"
    }}>Listen</a>
(Wis), <a href="skillsAll.html#move-silently" style={{
      "color": "rgb(87, 158, 182)"
    }}>Move Silently </a>(Dex), <a href="skillsAll.html#open-lock" style={{
      "color": "rgb(87, 158, 182)"
    }}>Open
Lock</a>
(Dex), <a href="skillsAll.html#perform" style={{
      "color": "rgb(87, 158, 182)"
    }}>Perform</a> (Cha), <a href="skillsAll.html#profession" style={{
      "color": "rgb(87, 158, 182)"
    }}>Profession</a>
(Wis), <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a> (Int), <a href="skillsAll.html#search" style={{
      "color": "rgb(87, 158, 182)"
    }}>Search </a>(Int),
    <a href="skillsAll.html#sense-motive" style={{
      "color": "rgb(87, 158, 182)"
    }}>Sense Motive</a> (Wis), <a href="skillsAll.html#sleight-of-hand" style={{
      "color": "rgb(87, 158, 182)"
    }}>Sleight
of Hand</a> (Dex), <a href="skillsAll.html#spot" style={{
      "color": "rgb(87, 158, 182)"
    }}>Spot</a> (Wis), <a href="skillsAll.html#swim" style={{
      "color": "rgb(87, 158, 182)"
    }}>Swim</a>
(Str), <a href="skillsAll.html#tumble" style={{
      "color": "rgb(87, 158, 182)"
    }}>Tumble </a>(Dex), <a href="psionicSkills.html#use-psionic-device" style={{
      "color": "rgb(87, 158, 182)"
    }}>Use Psionic Device</a> (Cha), and <a href="skillsAll.html#use-rope" style={{
      "color": "rgb(87, 158, 182)"
    }}>Use
Rope</a> (Dex).
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
6 + Int modifier.</p>
    <a id="table-the-elocator"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Elocater</span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "24%"
          }}>Special</th>
          <th style={{
            "width": "26%"
          }}>Powers Known</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+2</td>
          <td>Scorn earth, Sidestep Charge
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+3</td>
          <td>+3</td>
          <td>Opportunistic strike +2
          </td>
          <td>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>Dimension step 
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>Flanker
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>Opportunistic strike +4 
          </td>
          <td>-
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>Transporter
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>Capricious step
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6</td>
          <td>+2</td>
          <td>+6</td>
          <td>+6</td>
          <td>Opportunistic strike +6
          </td>
          <td>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6</td>
          <td>+3</td>
          <td>+6</td>
          <td>+6</td>
          <td>Dimension spring attack
          </td>
          <td>+1 level of existing manifesting class
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">+7</td>
          <td className="last-row">Accelerated action
          </td>
          <td className="last-row">+1 level of existing manifesting class
          </td>
        </tr>
      </tbody>
    </table>
    <a id="elocator-class-features"></a><h6>Class Features</h6>
All the following are class features of the elocater prestige class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Elocaters are proficient with all simple and martial weapons and with
light armor.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> At every
level indicated on the table, the character gains additional power
points per day and access to new powers as if she had also gained a
level in whatever manifesting class she belonged to before she added
the prestige class. She does not, however, gain any other benefit a
character of that class would have gained (bonus feats, metapsionic or
item creation feats, psicrystal special abilities, and so on). This
essentially means that she adds the level of elocater to the level of
whatever manifesting class the character has, then determines power
points per day, powers known, and manifester level accordingly.
If a character had more than one manifesting class before she became an
elocater, she must decide to which class she adds the new level of
elocater for the purpose of determining power points per day, powers
known, and manifester level.</p>
    <a id="elocator-scorn-earth"></a><p><span style={{
        "fontWeight": "bold"
      }}>Scorn Earth (Su):</span> At
1st level, an elocater&#8217;s feet lift from the ground. From now on, she
can float a foot above the ground. Instead of walking she glides along,
unconcerned with the hard earth or difficult terrain. While she remains
within 1 foot of a flat surface of any solid or liquid, she can take
normal actions and make normal attacks, and can move at her normal
speed (she can even &#8220;run&#8221; at four times her normal speed). However, at
distances higher than 1 foot above any surface, her speed diminishes to
10 feet per round.
While she remains within 1 foot of a surface, she can make melee and
ranged attacks normally, but if she moves any higher, she incurs the
penalties on melee and ranged attack rolls as if she were the subject
of the <a href="psionicPowersGtoP.html#levitate-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic levitate</a> power.</p>
    <a id="elocator-sidestep-charge"></a><p><span style={{
        "fontWeight": "bold"
      }}>Sidestep Charge (Ex):</span>
At 1st level, an elocater gains <a href="psionicFeats.html#sidestep-charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sidestep Charge</a> as a bonus feat,
even
if she does not meet the prerequisites. If the character already has
this feat, she gains no benefit.</p>
    <a id="elocator-opportunistic-strike"></a><p><span style={{
        "fontWeight": "bold"
      }}>Opportunistic Strike (Ex):</span>
Beginning at 2nd level, an elocater&#8217;s hyperawareness of spatial
relations gives her an instinctive view of the battlefield, which
allows her to make a cunning attack against distracted opponents. The
elocater gains a +2 insight bonus on her attack roll and her damage
roll (if the attack hits) for the first attack she makes against an
opponent that has been dealt damage in melee by another character since
the elocater&#8217;s last action. At 5th level the insight bonus increases to
+4, and at 8th level the insight bonus increases to +6.</p>
    <a id="elocator-dimension-step"></a><p><span style={{
        "fontWeight": "bold"
      }}>Dimension Step (Su):</span>
An elocater of 3rd level or higher can slip psionically between spaces
as if using the <a href="psionicPowersDtoF.html#dimension-door-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic dimension door</a> power,
once per day. The
elocater cannot bring any other creatures with her. Her manifester
level for this effect is equal to her elocater level.</p>
    <a id="elocator-flanker"></a><p><span style={{
        "fontWeight": "bold"
      }}>Flanker (Ex):</span> An
elocater of 4th level or higher can flank enemies from seemingly
impossible angles. She can designate any adjacent square as the square
from which flanking against an ally is determined (including the square
where she stands, as normal). She can designate the square at the
beginning of her turn or at any time during her turn. The designated
square remains her effective square for flanking until she is no longer
adjacent to it or until she chooses a different square (at the start of
one of her turns). The character can even choose a square that is
impassable or occupied.</p>
    <a id="elocator-transporter"></a><p><span style={{
        "fontWeight": "bold"
      }}>Transporter (Ex):</span> At
6th level, an elocater learns both <a href="psionicPowersQtoW.html#teleport-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic teleport</a> and <a href="psionicPowersGtoP.html#plane-shift-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic plane
shift</a>. These powers are in addition to any powers the elocater
normally
learns by advancing a level.
The elocater treats these powers as if they were 3rd-level powers on
her class list. This means, among other things, that manifesting these
powers costs 5 power points. (If the character does not have a high
enough manifester level to manifest 3rd-level powers the character
cannot manifest these powers until she has attained the required
manifester level.)</p>
    <a id="elocator-capricious-step"></a><p><span style={{
        "fontWeight": "bold"
      }}>Capricious Step (Ex):</span>
At 7th level, an elocater can take an extra 5-foot step in any round
when she doesn&#8217;t perform any other movement (except for the first
5-foot step). Like the first, the second 5-foot step does not provoke
attacks of opportunity. The character can take the extra 5-foot step
immediately after taking the first, or wait until the end of her other
actions for the round. In all other ways, the rules for taking a 5-foot
step apply.</p>
    <a id="elocator-dimension-spring-attack"></a><p><span style={{
        "fontWeight": "bold"
      }}>Dimension Spring Attack (Su):</span>
An elocater of 9th level or higher can use her dimension step ability
in conjunction with her <a href="featsAll.html#spring-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spring Attack</a> feat once per day.
This ability
can be used only against opponents within 60 feet to which the elocater
has line of sight. She can dimension step up to the target, use Spring
Attack, and then use dimension step to return to her starting point.
(When she uses this ability, the total distance she can travel before
and after the attack is not limited by her speed.) The use of this
ability counts as her use of the dimension step ability on that day
(and this ability is not available during a day when she has already
used dimension step).</p>
    <a id="elocator-accelerated-action"></a><p><span style={{
        "fontWeight": "bold"
      }}>Accelerated Action (Su):</span>
When she attains 10th level, an elocater can accelerate herself and
thereby take more actions than normal. An elocater can accelerate
herself for a total of 5 rounds per day. She can choose to parcel out
her accelerated actions in 1-round increments. (This effect is not
cumulative with similar effects that provide additional actions, such
as <a href="psionicPowersQtoW.html#schism" style={{
        "color": "rgb(87, 158, 182)"
      }}>schism</a> or a <a href="spellsHtoL.html#haste" style={{
        "color": "rgb(87, 158, 182)"
      }}>haste </a>spell
- and in fact an elocater can&#8217;t take an
accelerated action if affected by these or similar effects.)
If she makes a full attack while accelerated, an elocater can make one
extra attack with any weapon she is holding. The attack is made using
her full base attack bonus, plus any modifiers appropriate to the
situation. If the elocater uses her accelerated action to move, she
gains an enhancement bonus to her speed of +30 feet. The elocater can
use her accelerated action to manifest a power, as long as she has not
already manifested a power in the current round and the one she wants
to manifest has a manifesting time of 1 standard action or shorter.
While accelerated, she gains a +2 dodge bonus on attack rolls and
Reflex saves and a +2 dodge bonus to Armor Class. Any condition that
makes her lose her Dexterity bonus to Armor Class (if any) also makes
her lose these dodge bonuses. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      